header {
  &.header-nav {
    box-shadow: 0 2px 2px #00000029;
    background: $color-grey-white;
    z-index: 100;
    position: fixed;
    width: 100%;

    @include media-breakpoint-up(md) {
      position: relative;
    }

    .navbar-brand {
      img {
        width: 225px;
      }
    }

    .profile {
      span {
        color: $color-blue1;
        font-size: 16px;
        font-family: $roboto-light;

        @include media-breakpoint-up(md) {
          font-size: 22px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 28px;
        }
      }
    }

    .navbar {
      padding-top: 0;
      padding-bottom: 0;

      @include media-breakpoint-up(lg) {
        padding-left: 30px;
      }
    }
  }
}

aside {
  &.side-nav {
    transition: 0.5s ease-in-out all;
    left: -500px;
    position: fixed;
    background: $color-white;
    bottom: 0;
    top: 95px;
    height: 100%;
    width: 80%;
    padding-top: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);

    @include media-breakpoint-up(sm) {
      width: 50%;
      left: -52%;
    }

    @include media-breakpoint-up(md) {
      position: relative;
      left: 0;
      width: 160px;
      box-shadow: none;
      top: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }

    &.show {
      left: 0;
    }

    .nav-item {
      a {
        font-weight: 400;
        color: $color-black;
        display: block;
        padding: 3px 15px;
        text-decoration: none;
        font-family: $source-sans;

        @include media-breakpoint-up(lg) {
          padding: 3px 15px 3px 30px;
          margin-bottom: 10px;
          font-size: 17px;
        }
      }

      &.name {
        padding: 3px 15px;
        color: $color-black;
        font-size: 16px;
      }

      button {
        font-weight: 700;
        color: $color-black;
        display: block;
        padding: 3px 15px;
        text-decoration: none;
        margin-top: 30px;
        background: transparent;
        border: none;

        @include media-breakpoint-up(lg) {
          margin-top: 50px;
          padding: 3px 22px;
          margin-bottom: 10px;
          font-size: 17px;
        }
      }

      &.active {
        a {
          background: $color-blue;
          color: $color-white;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
}

.nav-item2 {
  font-weight: 400;
  color: $color-black;
  padding: 3px 15px;
  display: block;
  font-family: $source-sans;
  font-size: 17px;
  padding-left:30px;
  margin-bottom:10px;
  cursor:pointer;
  text-decoration: none;
  background: transparent;
  border: none;
 
  &.active {
    background: $color-blue;
    color: $color-white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    text-decoration: none;
  }
}

main {
  &.login {
    background: $color-grey-white;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    section {
      padding: 15px;
      @include media-breakpoint-up(lg) {
        width: 325px;
        padding: 0;
      }
    }

    img {
      &.logo {
        width: 325px;
        margin-bottom: 25px;
      }
    }

    .MuiFormControl-root label {
      font-family: $roboto-light;
    }
  }

  &.dashboard {
    padding-top: 95px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      padding-top: 0px;
      padding-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 0px;
      padding-bottom: 20px;
    }

    .MuiTabs-indicator {
      background-color: transparent;
    }

    .filter {
      .MuiFormControl-root {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .dash-content {
      z-index: 5;
      position: relative;
      padding: 20px 20px 20px 40px;

      @include media-breakpoint-up(md) {
        max-width: calc(100% - 160px);
      }

      .title-dashboard {
        max-width: 750px;
      }
    }
    .search-bar {
      button {
        padding: 0;
        font-size: 18px;
        color: $color-blue;
      }

      input {
        padding: 5px 10px;
        font-size: 15px;
        border: none;
      }

      .MuiInputBase-root {
        box-shadow: 1px 1px 1px #00000029;
        background: $color-grey-white;
        border-radius: 3px;
      }

      fieldset {
        border: none;
      }
    }

    .MuiPaper-root {
      background: $color-grey-white;
      padding: 25px;
      transition: 0.3s linear all;
      color: $color-black;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        bottom: 0;
        position: absolute;
        border-bottom: 1px solid $color-black;
        z-index: 1;
        left: 0;
      }

      .MuiSelect-selectMenu {
        min-width: 100px;
        text-align: left;
      }
      &.MuiAppBar-root {
        box-shadow: none;
        background: $color-grey1;
        margin-top: -15px;
        padding-bottom: 0;

        .MuiTabs-root {
          min-height: auto;
          margin-top: 20px;
          position: relative;
          z-index: 2;

          .MuiButtonBase-root {
            color: $color-grey2;
            min-width: 80px;
            background: $color-grey3;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-transform: none;
            font-size: 16px;
            margin: 0 3px;
            min-height: auto;
            border-bottom: 1px solid $color-black;

            &.Mui-selected {
              background: $color-white;
              border: 1px solid $color-black;
              border-bottom: none;
              color: $color-black;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .PrivateTabIndicator-colorPrimary-80,
        .PrivateTabIndicator-colorPrimary-357 {
          display: none;
        }
      }

      &.hide {
        visibility: hidden;
        opacity: 0;
        display: none;
        transition: visibility 0s 2s, opacity 2s linear;
      }

      &.show {
        visibility: visible;
        opacity: 1;
        display: block;
        transition: opacity 2s linear;
      }

      h3 {
        color: $color-blue1;
        font-weight: 300;
        font-family: $roboto-light;
        font-size: 23px;

        @include media-breakpoint-up(lg) {
          font-size: 28px;
        }
      }

      .numb {
        &::first-letter {
          color: #0b2e13;
        }
      }

      p {
        font-family: $roboto-light;

        @include media-breakpoint-up(md) {
          font-size: 15px;
        }

        strong {
          font-family: $source-sans;
          font-weight: normal;
        }

        &.title {
          font-family: $roboto-medium;

          @include media-breakpoint-up(md) {
            font-size: 17px;
          }

          strong {
            font-family: $roboto-medium;
          }
        }

        &.med {
          @include media-breakpoint-up(md) {
            font-size: 17px;
          }
        }

        &.desc {
          font-family: $gibson-light;
          font-size: 15px;

          &-strong {
            font-size: 15px;
            font-family: $gibson;
            font-weight: normal;
          }
        }
      }

      .card-activity {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid;
        border-radius: 10px;
      }

      .form-group {
        label,
        legend {
          font-family: $roboto-light;

          @include media-breakpoint-up(md) {
            font-size: 17px;
          }
        }
      }
    }

    .table-wrapper {
      max-width: 100%;
      overflow-x: scroll;

      @include media-breakpoint-up(lg) {
        overflow: hidden;
      }
    }

    .MuiTablePagination-caption {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .MuiIconButton-root {
      padding: 5px;
    }

    .content-mid {
      @include media-breakpoint-up(lg) {
        max-width: 47.66667%;
        padding-left: 8px;
        flex: 0 0 47.66667%;
      }
    }

    .content-right {
      @include media-breakpoint-up(lg) {
        max-width: 52.333%;
        padding-left: 5px;
        padding-right: 0;
        flex: 0 0 52.333%;
      }
    }

    .map-container {
      min-height: 80px;

      .map {
        img {
          //width: 30px!important;
        }
      }
    }
  }
}
